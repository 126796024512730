
import axios from "axios";

const network = axios.create({
    // baseURL: "http://172.29.100.97:8085",
    baseURL: "https://xftiger.com",
    // baseURL: "http://localhost:8085",
    timeout: 1000 * 30,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true
});

export default {

    async post(url, param) {
        var xfResponse = {
            code: 0,
            msg: 'OK',
            result: null
        }
        try {
            var response = await network.post(url, param)
            if (response.status == 200) {
                xfResponse = response.data
            } else {
                xfResponse.code = response.status
                xfResponse.msg = response.statusText
            }
        } catch (error) {
            xfResponse.code = 1001
            xfResponse.msg = "网络错误"
            console.log(error);
        }
        return xfResponse
    }
}

// 请求拦截器
network.interceptors.request.use(
    config => {
      // 在发送请求之前做些什么，例如设置Token
    //   config.headers.Authorization = 'Bearer ' + getToken();
      return config;
    },
    error => {
      return Promise.reject(error);
    }
);

// 响应拦截器
network.interceptors.response.use(
    response => {
      // 对响应数据做点什么
      return response;
    },
    error => {
      // 对响应错误做点什么
      return Promise.reject(error);
    }
);