<template>
  <div class="payment-selector">

    <div class="order_top_bg"></div>

    <div class="item-bg">

      <div class="item">
        <div class="cornner-bg-top-left"></div>
        <div class="cornner-bg-top-right"></div>
        <div class="cornner-bg-bottom-left"></div>
        <div class="cornner-bg-bottom-right"></div>

        <div class="order-info-sub">
          <div class="order-left">
            <label class="label-wrap">{{ this.content1 }}</label>
          </div>
          <div class="order-right"></div>
        </div>

        <div class="order-desc">
          <!-- <label class="label-wrap">{{ this.content2 }}</label> -->
          <div v-html="content2" class="label-wrap"></div>
        </div>
      </div>
    </div>

    <!-- 支付 -->
    <div class="item-bg" v-show="shouldShowPay">
      <div class="item">

        <div class="cornner-bg-top-left"></div>
        <div class="cornner-bg-top-right"></div>
        <div class="cornner-bg-bottom-left"></div>
        <div class="cornner-bg-bottom-right"></div>

        <div class="order-no-title">
          <label style="font-size: 14pt;">订单号：<b style="color: red;">{{ order.orderNo }}</b></label>
          <p>
            限时优惠：<b style="color: red; font-size: 18pt;">{{ this.order.price }}</b>
            <b class="original-price">原价：68.8</b>
          </p>
          <p style="color: #999999; font-size: 11pt;">活动截止日期到2024年10月1日</p>
        </div>

        <div class="pay-method">

          <div class="pay-method-item" :class="{ 'selected': isAliSelected }" @click="selectAliPay">
            <div class="pay-img-ali"></div>
            <p>支付宝</p>
          </div>
          <div class="space-width"></div>
          <div class="pay-method-item" :class="{ 'selected': isWChatSelect }" @click="selectWChatPay">
            <div class="pay-img-wx"></div>
            <p>微信</p>
          </div>
        </div>

        <div class="pay-ensure" @click="payAction"></div>

      </div>
    </div>

    <div class="item-bg" v-show="shouldShowResult">

      <div class="item">

        <div class="cornner-bg-top-left"></div>
        <div class="cornner-bg-top-right"></div>
        <div class="cornner-bg-bottom-left"></div>
        <div class="cornner-bg-bottom-right"></div>

        <div class="result-item-desc" v-show="isGetting"> 正在生成报告, 请稍候 </div>
        <label class="content-result">{{ resultConent }}</label>
        <!-- <ul v-show="!isGetting">
          <li v-for="item in resultList" :key="resultList.indexOf(item)">
            <div class="result-item-bg">
              <div>
                {{ item.title }}
              </div>
              <div class="result-item-desc"> {{ item.desc }} </div>
              <div class="result-item-blur" v-show="didPaySuccess"></div>
              <div class="result-item-lock" v-show="didPaySuccess"></div>
            </div>
          </li>
        </ul> -->

      </div>
    </div>
  </div>
  <XFLoading :is-show="isLoading"></XFLoading>
  <XFToast ref="toast"></XFToast>
</template>


<script>
import network from "./network/xf_network.js";
import XFLoading from './commons/XFLoading.vue'
import XFToast from './utils/XFToast.vue'
import { getLunar } from 'chinese-lunar-calendar';

export default {

  name: 'PaymentSelector',

  components: {
    XFLoading,
    XFToast
  },

  data() {
    return {
      order: {},
      // birthdate: { "year": 1, "month": 1, "day": 1, "hour": 1 },
      intervalId: null, // 定时器id
      content1: "",
      content2: "",
      contentList1: [],
      contentList2: [],
      /// 是否显示支付方式
      shouldShowPay: false,
      /// 是否显示结果页
      shouldShowResult: false,
      isAliSelected: true,
      isWChatSelect: false,
      didPaySuccess: false,
      /// 结果数据
      resultList: [
        { title: "您的事业运势", desc: "订单尚未完成支付，无法查看详细信息" },
        { title: "您的财运分析", desc: "订单尚未完成支付，无法查看详细信息" },
        { title: "您的感情运势", desc: "订单尚未完成支付，无法查看详细信息" },
        { title: "您的健康运势", desc: "订单尚未完成支付，无法查看详细信息" },
        { title: "您的综合建议", desc: "订单尚未完成支付，无法查看详细信息" }
      ],
      resultConent: "",
      isGetting: false, // 正在生成报告
      shouldLoopState: true,
      shouldLoopResult: false,
      /// 文案加载中
      isLoadingContent: true,
      isLoading: false
    };
  },

  created() {
    const { orderNo } = this.$route.query
    this.requestForOrderDetail(orderNo)
  },

  methods: {

    initData() {
      this.startLoop()
      this.handleContent()
      document.addEventListener('visibilitychange', () => {
        if (!document.hidden) {
          // 页面从后台返回前台，重启定时器
          this.requestForOrderState(this.order.orderNo, false)
          this.startLoop()
        } else {
          // 页面被隐藏，停止定时器
          this.stopLoop()
        }
      })
      window.onerror = function (message, source, lineno, colno, error) {
        // 记录错误信息到控制台
        console.error('An error occurred: ', message, source, lineno, colno, error);
        // alert("An error occurred:" + source + "\n" + lineno + "\n" + colno + "\n" + error)
        // 可以在这里调用一些错误报告的服务，例如 Sentry
        // Sentry.captureException(error);

        // 如果你不希望浏览器显示错误，可以返回 true
        return true;
      };
    },

    async payAction() {
      if (this.isAliSelected) {
        this.order.selectedPayment = 1;
      } else {
        this.order.selectedPayment = 2;
      }
      this.isLoading = true
      let res = await network.post("/api/pay/payOrder", { orderNo: this.order.orderNo, payAmount: 0.01, payMethod: this.order.selectedPayment })
      this.isLoading = false
      if (res.code == 0) {
        let payUrl = res.result["payUrl"]
        // window.open(payUrl, '_blank')
        window.location.href = payUrl
      } else {
        this.showToast('服务器异常')
      }
    },

    getBirthDayStr() {
      const date = new Date(this.order.birthDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的  
      const day = String(date.getDate()).padStart(2, '0');
      return year + "年" + month + "月" + day + "日"
    },

    /// 获取农历日期
    getLunarStr() {
      const date = new Date(this.order.birthDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的  
      const day = String(date.getDate()).padStart(2, '0');
      const lunar = getLunar(year, month, day)
      return lunar.lunarYear + lunar.dateStr
    },

    getSexString() {
      if (this.order.sex == 1) {
        return "男"
      } else {
        return "女"
      }
    },

    /// 开始轮训
    startLoop() {
      self.shouldLoopState = this.order.payState != 2
      this.intervalId = setInterval(() => {
        if (this.shouldLoopState) {
          this.requestForOrderState(this.order.orderNo, false)
        } else if (this.shouldLoopResult) {
          this.requestForGetResult(this.order.orderNo)
        }
      }, 3500)
    },

    /// 取消定时器
    stopLoop() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },

    /// 查询订单状态
    async requestForOrderState(orderNo, isFirst) {
      if (this.order.payState == 2) {
        self.shouldLoopState = false
        this.shouldLoopResult = this.resultConent.length <= 0
        return
      }
      let res = await network.post('/api/ttcs/orderState', { orderNo: orderNo })
      if (res.code == 0) {
        const { payState } = res.result
        this.order.payState = payState
        if (payState == 2) { /// 支付成功
          this.reloadState()
          this.shouldLoopState = false
          this.shouldLoopResult = this.resultConent.length <= 0
          if (isFirst) {
            this.requestForGetResult(orderNo)
          }
        }
      }
    },

    /// 获取订单详情
    async requestForOrderDetail(orderNo) {
      let res = await network.post('/api/ttcs/orderState', { orderNo: orderNo })
      if (res.code == 0) {
        const { orderNo, name, sex, birthDate, payState, bazi, zodiacs, resultContent } = res.result
        this.order = { orderNo, name, sex, birthDate, payState, price: 25.6, selectedPayment: null, bazi, zodiacs, resultContent }
        if (this.order.resultContent != null) {
          this.resultConent = this.order.resultContent
        }
        if (payState == 2) { /// 订单已支付
          this.didPaySuccess = true
          this.shouldLoopState = false
          this.shouldLoopResult = true
        }
        this.reloadState()
        this.initData()
      } else {
        this.showToast(res.msg)
      }
    },

    /// 测算结果
    async requestForGetResult(orderNo) {
      if (this.resultConent.length > 0) {
        this.shouldLoopResult = false
        this.stopLoop()
        return
      }
      let res = await network.post('/api/ttcs/getResult', { orderNo: orderNo })
      if (res.code == 0) {
        this.resultConent = res.result
        this.shouldLoopResult = false
        this.handleCesuanResult(res.result)
        this.stopLoop() // 取消轮训结果
        this.reloadState()
      }
    },

    /// 刷新状态
    reloadState() {
      if (this.isLoadingContent) { /// 加载中
        this.shouldShowPay = false
        this.shouldShowResult = false
        return
      }
      this.shouldShowPay = this.order.payState != 2
      this.shouldShowResult = !this.shouldShowPay
      this.isGetting = this.shouldShowResult && this.resultConent.length <= 0
    },

    handleContent() {
      this.isLoadingContent = true
      var contentStr1 = "姓名：" + this.order.name + "\n" + "性别：" + this.getSexString() + "\n出生日期：" + this.getBirthDayStr() + "（农历" + this.getLunarStr()+ "）"
      var contentStr2 = "您的生肖是" + "<b style=\"color: red;>\">" + this.order.zodiacs + "</b>" + "，" + "生辰八字是 <b style=\"color: red;>\"" +
        this.order.bazi + "</b>。 以下是你的八字全方面人生分析预测。一定要看看哦，相信会对你有很大的帮助！"
      this.contentList1 = contentStr1.split("")
      this.contentList2 = contentStr2.split("")
      this.loopContent(0)
    },

    loopContent(index) {

      if (index < this.contentList1.length) {
        const element = this.contentList1[index];
        this.content1 += element
        setTimeout(() => {
          this.loopContent(index + 1)
        }, 100);
      } else if (index < this.contentList1.length + 5/*this.contentList2.length*/) {
        const element = this.contentList2[index - this.contentList1.length];
        this.content2 += element
        setTimeout(() => {
          this.loopContent(index + 1)
        }, 100);
      } else {
        this.isLoadingContent = false
        this.content2 = "您的生肖是" + "<b style=\"color: red;>\">" + this.order.zodiacs + "</b>" + "，" + "生辰八字是 <b style=\"color: red;>\">" +
          this.order.bazi + "</b>。 以下是你的八字全方面人生分析预测。一定要看看哦，相信会对你有很大的帮助！"
        this.reloadState()
      }
    },

    selectAliPay() {
      this.isAliSelected = true
      this.isWChatSelect = false
    },

    selectWChatPay() {
      this.showToast("暂未开通，敬请期待")
      // this.isAliSelected = false
      // this.isWChatSelect = true
    },

    /// 处理结果信息
    handleCesuanResult(resultStr) {
      this.resultList = []
      /// 事业运势
      const shiyeStr = this.handleItemResult("事业运势\n", resultStr)
      if (shiyeStr.length > 0) {
        this.resultList.push({ title: "您的事业运势", desc: shiyeStr })
      }
      /// 财运分析
      const cyStr = this.handleItemResult("财运分析\n", resultStr)
      if (cyStr.length > 0) {
        this.resultList.push({ title: "您的财运分析", desc: cyStr })
      }
      /// 感情运势
      const ganqingStr = this.handleItemResult("感情运势\n", resultStr)
      if (ganqingStr.length > 0) {
        this.resultList.push({ title: "您的感情运势", desc: ganqingStr })
      }
      /// 健康运势
      const jkStr = this.handleItemResult("健康运势\n", resultStr)
      if (jkStr.length > 0) {
        this.resultList.push({ title: "您的健康运势", desc: jkStr })
      }
      /// 综合建议
      const zhStr = this.handleItemResult("综合建议\n\n", resultStr)
      if (zhStr.length > 0) {
        this.resultList.push({ title: "您的综合建议", desc: zhStr })
      }
    },

    /// 处理item结果
    handleItemResult(itemStr, resultStr) {
      resultStr = "" + resultStr
      if (resultStr.includes(itemStr)) {
        const list = resultStr.split(itemStr)
        const subList = list[list.length - 1].split("\n")
        if (subList.length > 0) {
          return subList[0]
        }
      }
      return ""
    },

    /// Toast提示
    showToast(message) {
      this.$refs.toast.show(message);
    },
  },

};
</script>

<style scoped>
.payment-selector {
  max-width: 500px;
  /* 设置最大宽度 */
  margin: auto;
  text-align: center;
  /* padding-top: 50px; */
  min-height: 100vh;
  /* 至少为视口高度 */
  background: #fccca4;
  /* 背景颜色 */
  overflow: auto;
  /* 内容超出时显示滚动条 */
}

.order_top_bg {
  width: 100%;
  background-image: url('~@/assets/ic_result_top.png');
  background-size: contain;
  /* 根据需要调整 */
  background-repeat: no-repeat;
  aspect-ratio: 2;
}

.order-info-sub {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.item {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 25pt 12pt 25pt 12pt;
  box-shadow: inset 0px 0px 20pt rgb(249, 190, 131);
  background-color: rgba(254, 252, 233, 1);
}

.item-bg {
  box-shadow: 0px 0px 5pt rgb(247, 204, 164);
  margin: 12pt;
}

.order-desc {
  text-align: left;
  margin-top: 10pt;
  text-indent: 2em;
}

.order-left {
  width: 75%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 1rem;
}

.order-right {
  width: 25%;
  background-image: url('~@/assets/ic_bagua.png');
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 1;
}

.result-item-bg {
  position: relative;
  border-radius: 8pt;
  background-color: white;
  padding-top: 15pt;
  padding-bottom: 15pt;
  margin-top: 15pt;
  text-align: left;
  /* height: 200pt; */
}

/* 高斯模糊 */
.result-item-blur {
  top: 0;
  left: 0;
  position: absolute;
  /* 固定定位，覆盖整个视口 */
  width: 100%;
  height: 100%;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  /* 黑色半透明背景 */
  filter: blur(8pt);
  /* 高斯模糊效果 */
  z-index: 998;
  /* 确保蒙层在页面元素之上 */
}

.result-item-lock {
  background-image: url('~@/assets/ic_lock.png');
  background-size: contain;
  position: absolute;
  height: 15pt;
  width: 15pt;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.result-item-desc {
  padding-left: 5pt;
  padding-right: 5pt;
  /* text-indent: 2em; */
}

.label-wrap {
  white-space: pre-line;
}

.cornner-bg {
  background-image: url('~@/assets/ic_item_top_left.png');
  background-size: contain;
  position: absolute;
  height: 50pt;
  width: 50pt;
}

.cornner-bg-top-left {
  background-image: url('~@/assets/ic_item_top_left.png');
  background-size: contain;
  position: absolute;
  height: 18pt;
  width: 18pt;
  top: 5pt;
  left: 5pt;
  /* z-index: 1000 */
  /* transform: rotateZ('90'); */
}

.cornner-bg-top-right {
  background-image: url('~@/assets/ic_item_top_left.png');
  background-size: contain;
  position: absolute;
  height: 18pt;
  width: 18pt;
  top: 5pt;
  right: 5pt;
  transform: rotate(90deg)
}

.cornner-bg-bottom-left {
  background-image: url('~@/assets/ic_item_top_left.png');
  background-size: contain;
  position: absolute;
  height: 18pt;
  width: 18pt;
  bottom: 5pt;
  left: 5pt;
  transform: rotate(-90deg)
}

.cornner-bg-bottom-right {
  background-image: url('~@/assets/ic_item_top_left.png');
  background-size: contain;
  position: absolute;
  height: 18pt;
  width: 18pt;
  bottom: 5pt;
  right: 5pt;
  transform: rotate(180deg)
}

.pay-method {
  display: flex;
  flex-direction: row;
  margin-top: 10pt;
  margin-bottom: 10pt;
}

.pay-method-item {
  display: flex;
  position: relative;
  width: 50%;
  /* aspect-ratio: 4.8; */
  height: 2.8rem;
  justify-content: center;
  align-items: center;
  border-radius: 5pt;
  background-color: #F1F3F8;
  border: 1pt solid transparent;
}

.pay-method-item.selected {
  border-color: green;
  background-color: white;
}

/* 对号图标样式 */
.pay-method-item.selected::before {
  /* 对号字符 */
  position: absolute;
  top: 0px;
  /* 根据需要调整位置 */
  right: 0px;
  /* 根据需要调整位置 */
  /* 对号大小 */
  color: green;
  /* 对号颜色 */
}

.pay-img-ali {
  background-image: url('~@/assets/ic_alipay.png');
  background-size: contain;
  height: 20pt;
  width: 20pt;
  margin-right: 10pt;
}

.pay-img-wx {
  background-image: url('~@/assets/ic_wchat.png');
  background-size: contain;
  height: 20pt;
  width: 20pt;
  margin-right: 10pt;
}

.pay-ensure {
  background-image: url('~@/assets/ic_unlock_ok.png');
  background-size: 100% 100%;
  width: 90%;
  aspect-ratio: 6.5;
  margin: auto;
  background-repeat: no-repeat;
}

.order-no-title {
  text-align: left;
}

.original-price {
  text-decoration: line-through;
  /* 划线效果 */
  color: #ccc;
  /* 通常划线价的颜色会淡一些，表示不再有效 */
  margin-left: 8pt;
}

.space-width {
  width: 10pt;
}

ul {
  list-style-type: none;
  /* 去掉圆点 */
}

.content-result {
  white-space: pre-line;
  text-align: left;
}
</style>