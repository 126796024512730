<template>
  <div class="ttcs-home">
    <div class="ttcs_bg">
      <div class="ttcs_top_item">
        <p style="font-size: 18pt;font-weight: 500;"> {{ getNowTimeStr() }} </p>
        <p style="font-size: 25pt;font-weight: 400; color: black;font-family: Monotype Corsiva;"> 八字测算 运势分析 </p>
      </div>
    </div>
    <div class="ttcs_input_bg">
      <div class="ttcs_input_item" :class="{ 'focused': isFocused }">
        <div class="input_text">
          您的姓名:
        </div>
        <input class="input-field" v-model="userName" @focus="isFocused = true" placeholder="输入姓名">
      </div>
      <div class="ttcs_input_item">
        <div class="input_text">您的性别:</div>
        <label for="male" style="font-size: 13pt;">男</label>
        <input type="radio" id="1" value="1" v-model="selectSex"/>

        <label for="male" style="font-size: 13pt;">女</label>
        <input type="radio" id="0" value="0" v-model="selectSex"/>
      </div>
      <div class="ttcs_input_item" @click="showDatePickerAction">
        <div class="input_text">出生日期:</div>
        <label style="font-size: 14pt; color: gray;">{{ selectedDateStr }}</label>
      </div>
      <div class="ttcs-ensure" @click="goResultPage"></div>
    </div>
    <div class="ttcs_second"></div>
    <div class="bg_home_4"></div>
    <div class="bg_home_5"></div>
    <div class="icp_bottom">
      <p style="font-size: 10pt;">联系我们：Ctrl_zqzl（微信）</p>
      <p style="font-size: 14pt;"> 武汉鑫新未来科技有限公司 </p>
      <a href="https://beian.miit.gov.cn" style="font-size: 11pt;color: blue;"> 鄂ICP备2024063541号-1 > </a>
    </div>
  </div>
  <div class="marquee-container">
    <p class="marquee-content" style="color: white;">
      {{ marqueeMsg }}
    </p>
  </div>
  <XFDatePicker :is-show="showDatePicker" @ensure="didSelectDate" @cancel="cancelDate"></XFDatePicker>
  <XFToast ref="toast"></XFToast>
  <XFLoading :is-show="isLoading"></XFLoading>
  <div class="stick-contact" @click="gotoOrderQuery">
    <p style="color: white;"> 订<br>单<br>查<br>询 </p>
  </div>

</template>

<script>

import network from "../network/xf_network.js";
import XFDatePicker from './XFDatePicker.vue'
import XFToast from '../utils/XFToast.vue'
import { handleWork } from '../utils/bazi.js';
import XFLoading from '../commons/XFLoading.vue'

export default {
  components: {
    XFDatePicker,
    XFToast,
    XFLoading
  },
  data() {
    return {
      model: null,
      isFocused: false, // 用于追踪输入框是否获得焦点
      userName: "",
      selectSex: 1,
      showDatePicker: false,
      selectDate: null,
      selectedDateStr: "选择日期", // 绑定的日期模型
      marqueeMsg: "我平台所有测试仅供娱乐，不得作为生活、工作之指导。我平台产品拒绝向未成年人提供服务，如未成年人使用且造成一切后果由其监护人自行承担。",
      isLoading: false
    };
  },
  mounted() {
    // this.fetchData();

  },
  methods: {

    /// 跳转订单页面
    async goResultPage() {
      if (this.userName.length <= 0) {
        this.showToast("请输入姓名")
        return
      }
      if (this.selectDate == null) {
        this.showToast('请选择出生日期')
        return
      }
      /// 测算八字
      var result = handleWork(
        this.selectDate.year,
        this.selectDate.month,
        this.selectDate.day,
        this.selectDate.hour
      );
      const bazi = result.bazi
      const zodiacs = result.shengxiao
      /// 转换时间戳
      let timeString = this.selectDate.year +
        "-" + String(this.selectDate.month).padStart(2, '0') +
        "-" + String(this.selectDate.day).padStart(2, '0');
      let birthDate = Date.parse(timeString)
      var netParam = { name: this.userName, sex: this.selectSex, birthDate: birthDate, bazi, zodiacs }
      this.isLoading = true
      var res = await network.post("/api/ttcs/createOrder", netParam)
      this.isLoading = false
      if (res.code == 0) {
        if (res.result != null) {
          // localStorage.setItem("orderInfo", {})
          let orderNo = res.result["orderNo"]
          // var params = {
          //   orderNo: orderNo,
          //   name: this.userName,
          //   sex: this.sex,
          //   birthdate: this.selectDate,
          //   bazi: bazi,
          //   zodiacs: zodiacs
          // }
          // localStorage.setItem("orderInfo", JSON.stringify(params))
          this.$router.push({
            path: '/paySelect',
            query: { orderNo }
          });

        } else {
          this.showToast('服务器异常')
          console.log(res)
        }
      } else {
        this.showToast('服务器异常')
        console.log(res)
      }

    },

    showToast(message) {
      this.$refs.toast.show(message);
    },

    /// 选择日期
    showDatePickerAction() {
      this.showDatePicker = true
    },

    didSelectDate(date) {
      this.selectDate = date
      this.selectedDateStr = date.year + "年" + date.month + "月" + date.day + "日" + this.getHourStr(date.hour)
      this.showDatePicker = false
    },

    cancelDate() {
      this.showDatePicker = false
    },

    getHourStr(index) {
      const chineseHours = [
        "时辰未知",
        "子时",  // 23:00 - 01:00
        "丑时",  // 01:00 - 03:00
        "寅时",  // 03:00 - 05:00
        "卯时",  // 05:00 - 07:00
        "辰时",  // 07:00 - 09:00
        "巳时",  // 09:00 - 11:00
        "午时",  // 11:00 - 13:00
        "未时",  // 13:00 - 15:00
        "申时",  // 15:00 - 17:00
        "酉时",  // 17:00 - 19:00
        "戌时",  // 19:00 - 21:00
        "亥时"   // 21:00 - 23:00
      ];
      return chineseHours[index];
    },

    getNowTimeStr() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0') // 月份从0开始，所以需要+1
      const day = date.getDate().toString().padStart(2, '0')
      return year + "-" + month + "-" + day

    },

    /// 查询订单
    gotoOrderQuery() {
      this.$router.push(
        { path: '/orderQuery', }
      )
    }
  },
};


</script>

<style scoped>
@import './date_picker.css';


.ttcs-home {
  max-width: 500px;
  /* 设置最大宽度 */
  margin: 0 auto;
  /* 居中显示 */
  padding: 0px;
  /* 内边距 */
  box-sizing: border-box;
  /* 边框计算在宽度内 */
  min-height: 100vh;
  /* 至少为视口高度 */
  /* background: #DDBA85; 背景颜色 */
  background-color: #F7CDA7;
  /* overflow: auto; */
  /* 内容超出时显示滚动条 */
  display: flex;
  flex-direction: column;
}

.ttcs-home button {
  margin: 20px 0;
  padding: 10px 20px;
  font-size: 16px;
}

.ttcs_bg {
  background-image: url('~@/assets/bg_main.webp');
  background-size: 100% 100%;
  /* 根据需要调整 */
  background-repeat: no-repeat;
  /* background-position: center; */
  width: 100%;
  aspect-ratio: 700/1000;
  display: flex;
}

.ttcs_top_item {
  position: relative;
  /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  /* padding-top: 20rem; */
  margin-top: 90%;
}

.ttcs_second {
  background-image: url('~@/assets/bg_home_3.png');
  background-size: 100% 100%;
  /* 根据需要调整 */
  background-repeat: no-repeat;
  /* background-position: center; */
  width: 100%;
  aspect-ratio: 554/274;
}

.ttcs_input_bg {
  background-image: url('~@/assets/bg_home_2.png');
  width: 100%;
  padding: 45pt 0 0 0;
  background-size: 100% 100%;
  /* 背景图覆盖整个元素 */
  background-repeat: no-repeat;
  /* 背景图不重复 */
  background-position: center;
  /* 背景图居中显示 */
  aspect-ratio: 100 / 65.0;
}

.ttcs_input_item {
  border-radius: 25px;
  border-width: 4px;
  border-color: #000000;
  border: 2px solid #ccc;
  background-color: #fcefca;
  width: 85%;
  aspect-ratio: 8.2;
  margin: 10px auto;
  display: flex;
  align-items: center;
}

.ttcs_input_item.focused {
  border-color: #42b983;
}

.input_text {
  font-size: 14pt;
  margin: 0px 15px;
  white-space: nowrap;
}

.input-field {
  /* flex-grow: 1; */
  padding: 5px;
  border: none;
  /* 输入框本身没有边框 */
  outline: none;
  /* 去除默认的聚焦轮廓 */
  background-color: transparent;
  font-size: 13pt;
  overflow: hidden;
  text-overflow: ellipsis;
  /* 显示省略号 */
  white-space: nowrap;
  /* 保持文本在一行显示 */
}

input[type="radio"] {
  margin-right: 10px;
  /* 右边距，根据需要调整 */
  margin-left: 3px;
}

.datepicker-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* 确保日期选择器在页面其他元素之上 */
}

.bg_home_4 {
  background-image: url('~@/assets/bg_home_4.png');
  background-size: 100% 100%;
  /* 背景图覆盖整个元素 */
  /* 根据需要调整 */
  background-repeat: no-repeat;
  /* background-position: center; */
  width: 100%;
  aspect-ratio: 750/968.0;
}

.bg_home_5 {
  background-image: url('~@/assets/bg_home_5.png');
  background-size: 100% 100%;
  /* 背景图覆盖整个元素 */
  /* 根据需要调整 */
  background-repeat: no-repeat;
  /* background-position: center; */
  width: 100%;
  aspect-ratio: 750/224.0;
}

.ttcs-ensure {
  /* position: fixed; */
  background-image: url('~@/assets/ic_lijicesuan.png');
  background-size: 100% 100%;
  width: 65%;
  height: 38pt;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10pt;
  border-radius: 5pt;
}

/* 跑马灯 */
.marquee-container {
  max-width: 500px;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 0;
  height: 2rem;
  background-color: #00000090;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  transform: translateX(-50%);
  line-height: 2rem;
}

.marquee-content {
  position: relative;
  display: inline-block;
  padding-left: 100%;
  /* 初始位置 */
  animation: marquee 12s linear infinite;
  /* 动画持续时间、类型和次数 */
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
    /* 滚动到最左边 */
  }
}

.icp_bottom {
  margin-top: 1rem;
  padding-bottom: 1rem;
  /* background-color: #F7CDA7;
  max-width: 500px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%); */
}

.stick-contact {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 4rem;
  right: 0;
  /* transform: translateY(-50%); */
  z-index: 5;
  width: 25pt;
  padding-top: 10pt;
  padding-bottom: 10pt;
  border-radius: 4pt 0pt 0pt 4pt;
  background-color: #00000090;
  justify-content: center;
}
</style>
